const defaultSettings = {
    filters: {},
    atr_model_type: "l_value_id_0",
    lines:[],
    linesAdditionalData: {},
    linesMutation: null,
    checkboxes: {},
    colorLines: {},
    colors: {
        "#379ce2": null,
        "#f5993f": null,
        "#f46080": null,
        "#9463f4": null,
        "#f4c555": null,
        "#00B8D9": null,
        "#F45D8B": null,
        "#B8E986": null,
        "#417505": null,
        "#4bb9b9": null,
    }
}

export default {
    namespaced: true,
    state: { ...defaultSettings }, // newBotSetting ??
    actions: {
        getColor({state, commit}, name) {
            for (let color in state.colors) {
                if (state.colors[color] === null) {

                    commit('setColor', {name, color})
                    return color
                }
            }
            return '#000'
        }
    },
    getters: {
        filters: function (state) {
            return state.filters
        },
        getLinesName(state) {
            return state.lines
        },
        linesMutation(state) {
            return state.linesMutation
        }
    },
    mutations: {
        /**
         * {name: 'properties', val: 'value'}
         * @param state
         * @param name
         * @param val
         * @private
         */
        __set(state, {name, val}) {
            state[name] = val
        },
        setColor(state, {name, color}) {
            state.colorLines[name] = color
            state.colors[color] = name
        },
        clearColor(state, name) {
            state.colors[state.colorLines[name]] = null
            delete state.colorLines[name]
        },
        registerCheckboxes(state, {name}) {
            state.checkboxes[name] = {}
        },
        addLinesAdditionalData( state, {name, data}) {
            state.linesAdditionalData[name] = data
        },
        runLinesMutation(state, data) {
            state.linesMutation = data
        },
        updateFilter(state, {name, val}) {
          state.filters =  { ...state.filters, ...{[name]: val} }
        },
        resetData(state) {
            state.filters = {}
            state.lines = []
            state.linesAdditionalData = {}
            state.linesMutation = null,
            state.checkboxes = {}
            state.colorLines = {}
            state.colors = {
                "#379ce2": null,
                "#f5993f": null,
                "#f46080": null,
                "#9463f4": null,
                "#f4c555": null,
                "#00B8D9": null,
                "#F45D8B": null,
                "#B8E986": null,
                "#417505": null,
                "#4bb9b9": null,
            }
        },
    }
}

